import * as React from "react";
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '@home/Layout';
import Seo from "../components/seo";
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import './404.scss';

const NotFoundPage = ({ data }) => {
  const content = data.prismicGomesHomepageGlobal.data;

  return (
    <Layout>
      <Seo title="404: Not found" />
      <div className="content">
      <h1>{content.heading.text.length > 0 ? content.heading.text : '404: Not Found'}</h1>
        <p>
          {
            content.paragraph ?
              content.paragraph
              :
              'You just hit a route that doesn\'t exist... the sadness.'
          }
        </p>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    prismicGomesHomepageGlobal {
      data {
        heading {
          text
        }
        paragraph
      }
    }
  }`;

NotFoundPage.propTypes = {
  data: PropTypes.object,
};

NotFoundPage.defaultProps = {
  data: {
    heading: { text: '404: Not Found' },
    paragraph: 'You just hit a route that doesn\'t exist... the sadness.'
  }
};

export default withPrismicUnpublishedPreview(NotFoundPage);
